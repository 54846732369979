<template>
	<div class="login">
		<div class="main">
			<div class="login-box">
				<div class="logoView">
					<img src="../../assets/img/logo_.png" alt="">
				</div>
				<!-- <div>
					<div>
						<h3>ようこそ　マキマキ</h3>
						<b></b>
						<div>
							<p>①マッチング機能</p>
							<p>②共同制作ワークフロー</p>
							<p>③募集応募電子契約</p>
							<p>④収益分配</p>
						</div>
						<div>	
							<p>サポートセンター：support@makimaki.co.jp</p>
						</div>
					</div>
					<p class="round"></p>
				</div>
				<div>
					<div>
						<div class="go-sign">
							<span @click="goSign">新規登録 ></span>
						</div>
						<div class="title">
							<h3>漫画共同製作システム</h3>
						</div>
						<div class="login">
							<el-form :model="form" ref="ruleForm" :rules="rules">
								<el-form-item prop="userName">
									<el-input v-model="form.userName" placeholder="メールアドレスを入力して下さい"></el-input>
								</el-form-item>
								<el-form-item prop="passWord">
									<el-input type="password" v-model="form.passWord" autocomplete="off" placeholder="パスワードを入力して下さい"></el-input>
								</el-form-item>
							</el-form>
							<div class="forgotPass" @click="forgotPass">忘记密码</div>
							
							<div @click="submit" class="login-btn">
								<span>ログイン</span>
							</div>
						</div>
					</div>
				</div> -->
				<div>
					<el-form :model="form" ref="ruleForm" :rules="rules">
						<el-form-item prop="userName">
							<el-input v-model="form.userName" placeholder="メールアドレスを入力して下さい"></el-input>
						</el-form-item>
						<el-form-item prop="passWord">
							<el-input type="password" v-model="form.passWord" autocomplete="off" placeholder="パスワードを入力して下さい"></el-input>
						</el-form-item>
					</el-form>
				</div>
				<div @click="submit" class="login-btn">
					<span>ログイン</span>
				</div>
				<div class="checkPass">
					<el-checkbox v-model="checked">パスワード保存</el-checkbox>
				</div>
				<div @click="goSign" class="sign-btn">
					<span>新規登録</span>
				</div>
				<div class="edit">
					<span></span>　
					<span @click="forgotPass">パスワード忘れ</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// const dom = document.getElementById('')
import utils from '@/assets/js/utils'
import service from '@/service'
export default {
    name: 'login',
	data() {
		//パスワード
		const checkPassWord = (rule,value,callback) => {
			if(!value) {
				return callback(new Error('パスワードを入力して下さい'))
			} 
			setTimeout(() => {
				if(value.length < 6) {
					return callback(new Error('パスワードは6位以上でお願いします'))
				} else {
					callback()
				}
			},500)
		}
		return {
			form: {
				userName: '',
				passWord: ''
			},
			checked: false,
			rules: {
				userName: [
					{required: true,validator: utils.checkUserEmail, trigger: 'blur' }
				],
				passWord: [
					{required: true,validator: checkPassWord, trigger: 'blur' }
				],
			}
		}
	},
	mounted() {
		let username = localStorage.getItem('username')
		if (username) {
			this.form.userName = localStorage.getItem('username')
			this.form.passWord = localStorage.getItem('password')// base64解密
			this.checked = true
		}
	},
	methods: {
		goSign() {
			this.$router.push('/sign')
		},
		forgotPass() {
			this.$router.push({
				path: '/forgotpass'
			})
		},
		submit(){
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					if (this.checked) {
						console.log(this.form.userName)
            			let password = this.form.passWord // base64加密
						localStorage.setItem('username', this.form.userName)
						localStorage.setItem('password', password)
					} else {
						localStorage.removeItem('username')
						localStorage.removeItem('password')
					}
					let params = {
						username: this.form.userName,
						password: this.form.passWord
					}
					service.home.getToken().then(res => {
						if(res.status == 200) {
							params["_token"] = res._token;
							service.home.userLogin(params).then(res => {
								if(res.status == 200) {
									this.$message.success("ログインしました")
									this.$router.push('/personal-center')
								} else {
									this.$message.error("ユーザーIDもしくはパスワードに誤っています")
								}
							}).catch(err => {
								console.log(err)
							})
						}
					})
				} else {
					return false;
				}
			})
		}
	}
}

</script>
<style lang="scss" scoped>
	.main {
		height: 100vh;
		// background: linear-gradient(0deg, rgba(240,55,71,0.5), rgba(255, 255, 255, 1));
		// background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/img/background1.jpg") no-repeat 0% 20%/ cover;
		background-image: url("../../assets/img/bac.png") ;
		.login-box {
			width: 330px;
			// height: 600px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			// border-radius: 40px;
			// display: flex;
			// >div {
			// 	width: 50%;
			// }
			// >div:nth-child(1) {
			// 	color: #fff;
			// 	background: rgba(20,45,65,.8);
			// 	border-top-left-radius:40px;
			// 	border-bottom-left-radius:40px;
			// 	overflow: hidden;
			// 	>div {
			// 		padding: 80px 0 0 88px;
			// 		h3 {
			// 			font-size: 35px;
			// 			letter-spacing: 3px;
			// 		}
			// 		b {
			// 			display: block;
			// 			height: 6px;
			// 			width: 150px;
			// 			background-color: #fff;
			// 			border-radius: 3px;
			// 			margin: 30px 0;
			// 		}
			// 		div {
			// 			p {
			// 				font-size: 24px;
			// 				letter-spacing: 2px;
			// 				padding: 7px 0;
			// 			}
			// 		}
			// 		div:last-child {
			// 			margin-top: 40px;
			// 		}				
			// 	}
			// 	.round {
			// 		width: 100px;
			// 		height: 100px;
			// 		border: 20px solid #fff;
			// 		border-radius: 100%;
			// 		position: relative;
			// 		bottom: 140px;
			// 		right: -87%;
			// 	}
			// }
			// >div:nth-child(2) {
			// 	background: rgba(255,255,255,.8);
			// 	border-top-right-radius:40px;
			// 	border-bottom-right-radius:40px;
			// 	>div {
			// 		padding: 20px 40px 40px 80px;
			// 		.go-sign {
			// 			display: flex;
			// 			justify-content: flex-end;
			// 			span {
			// 				color: #0051D9;
			// 				font-size: 24px;
			// 				letter-spacing: 2px;
			// 				cursor: pointer;
			// 			}
			// 		}
			// 		.title {
			// 			margin-top: 25px;
			// 			margin-bottom: 40px;
			// 			h3 {
			// 				font-size: 35px;
			// 				letter-spacing: 3px;
			// 				text-align: center;
			// 				color: #333;
			// 			}
			// 		}
			// 		.login {
			// 			padding: 0 30px;
			// 			::v-deep .el-form-item__content {
			// 				height: 80px;
			// 				margin-top: 15px;
			// 				.el-input {
			// 					height: 100%;
			// 					.el-input__inner {
			// 						height: 100%;
			// 						border-radius: 50px;
			// 						background: #eee;
			// 						font-size: 18px;
			// 						padding-left: 25px ;
			// 					}
			// 				}
			// 			}
			// 			.forgotPass {
			// 				cursor: pointer;
			// 				text-align: right;
			// 				color: #0051D9;
			// 			}
			// 			.login-btn {
			// 				background: #142D41;
			// 				height: 80px;
			// 				line-height: 80px;
			// 				text-align: center;
			// 				color: #fff;
			// 				border-radius: 50px;
			// 				width: 100%;
			// 				cursor: pointer;
			// 				margin-top: 40px;
			// 				span {
			// 					font-size: 30px;
			// 					letter-spacing: 3px;
			// 					font-weight: 500;
			// 				}
			// 			}
			// 		}
			// 	}
			// }
			.logoView {
				background-color: #fff;
				text-align: center;
				img {
					width: 75%;
					margin-bottom: 20px;
				}
			}
			.login-btn {
				background-color: #F03747;
				border-radius: 8px;
				color: #fff;
				text-align: center;
				line-height: 40px;
				cursor: pointer;
				font-size: 22px;
			}
			.text {
				padding: 15px 0;
			}
			.checkPass {
				margin-top: 20px;
				text-align: right;
			}
			.sign-btn {
				background: #FFFFFF;
				border: 1px solid #F03747;
				border-radius: 8px;
				text-align: center;
				line-height: 40px;
				cursor: pointer;
				font-weight: 500;
				font-size: 22px;
				color: #F03747;
				margin-top: 15px;
			}
			.edit {
				display: flex;
				padding: 15px 0;
				span {
					color: #1C71FF;
					cursor: pointer;
				}
				span:nth-child(1) {
					flex: 1;
				}
			}
		}
	}
</style>